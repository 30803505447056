/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:51:04
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 18:58:51
 */
import MobileBrand from './mobileBrand.vue'
export default MobileBrand
